$accent-color: #ffcf27;
$text-color: #313131;

@mixin forMobile {
  @media screen and (max-width: 400px) {
    @content;
  }
}

body {
  background-color: $accent-color;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: Manrope;
}

.App {
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
  width: 100%;
  min-height: 35rem;
  @include forMobile {
    min-height: 30rem;
  }
}

.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

a {
  font-family: Manrope;
  color: #007bbd;
}

.pincode-input-container {
  user-select: none !important;
  display: flex;
  flex-direction: row;
  .pincode-input-text {
    background: #fff !important;
    width: 88px !important;
    height: 101px !important;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.143575);
    border-radius: 5px;
    border: none !important;
    font-size: 2.4rem;
    color: $text-color !important;
    margin: 0 12px 0 15px !important;
    @include forMobile {
      width: 20vw !important;
      height: 25vw !important;
      margin: 2vw !important;
    }
  }
  .pincode-input-text:focus {
    outline: none;
    box-shadow: none;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-space {
  justify-content: space-between;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-align {
  align-items: center;
}

.flex-justify {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

@mixin boxShadow {
  -webkit-box-shadow: 0px 2px 12px -6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 2px 12px -6px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 12px -6px rgba(0, 0, 0, 0.3);
}

.pincode-input-container .pincode-input-text {
  margin: 0 !important;
}
